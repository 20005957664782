const ProfileList = [
  {
    Like: false,
    UserID: "K0001",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/2.jpg",
        original: "../images/2-orginal.jpg",
      },
      {
        thumbnail: "../images/3.jpg",
        original: "../images/3-orginal.jpg",
      },
    ],
    Name: "Name 1",
    Education: "B.Com",
    Income: "20000",
  },
  {
    Like: false,
    UserID: "K0002",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/2.jpg",
        original: "../images/2-orginal.jpg",
      },
      {
        thumbnail: "../images/4.jpg",
        original: "../images/4-orginal.jpg",
      },
      {
        thumbnail: "../images/5.jpg",
        original: "../images/5-orginal.jpg",
      },
    ],
    Name: "Name 2",
    Education: "B.Sc",
    Income: "30000",
  },
  {
    Like: false,
    UserID: "K0003",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 3",
    Education: "B.Tech",
    Income: "50000",
  },
  {
    Like: false,
    UserID: "K0004",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 4",
    Education: "MSc",
    Income: "25000",
  },
  {
    Like: false,
    UserID: "K0005",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 5",
    Education: "BS",
    Income: "35000",
  },
  {
    Like: false,
    UserID: "K0006",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 6",
    Education: "MBBS",
    Income: "60000",
  },
  {
    Like: false,
    UserID: "K0007",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 7",
    Education: "ML",
    Income: "80000",
  },
  {
    Like: false,
    UserID: "K0008",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 8",
    Education: "ME",
    Income: "120000",
  },
  {
    Like: false,
    UserID: "K0009",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 9",
    Education: "Mtech",
    Income: "70000",
  },
  {
    Like: false,
    UserID: "K0010",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 10",
    Education: "12th",
    Income: "15000",
  },
  {
    Like: false,
    UserID: "K0011",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 11",
    Education: "BCA",
    Income: "35000",
  },
  {
    Like: false,
    UserID: "K0012",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 12",
    Education: "MCA",
    Income: "45000",
  },
  {
    Like: false,
    UserID: "K0013",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 13",
    Education: "MTech",
    Income: "75000",
  },
  {
    Like: false,
    UserID: "K0014",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 14",
    Education: "BE",
    Income: "90000",
  },
  {
    Like: false,
    UserID: "K0015",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 15",
    Education: "BL",
    Income: "20000",
  },
  {
    Like: false,
    UserID: "K0016",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 16",
    Education: "12th",
    Income: "10000",
  },
  {
    Like: false,
    UserID: "K0017",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 17",
    Education: "BCA",
    Income: "28000",
  },
  {
    Like: false,
    UserID: "K0018",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 18",
    Education: "Btech",
    Income: "36000",
  },
  {
    Like: false,
    UserID: "K0019",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 19",
    Education: "Msc,Bed",
    Income: "48000",
  },
  {
    Like: false,
    UserID: "K0020",
    Photo: "../images/1.jpg",
    PhotoOrg: "../images/1-orginal.jpg",
    images: [
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
      {
        thumbnail: "../images/1.jpg",
        original: "../images/1-orginal.jpg",
      },
    ],
    Name: "Name 20",
    Education: "Msc",
    Income: "55000",
  },
];

const Stars = {
    options:[
    { id: 1, name: "Asuvini" },
  { id: 2, name: "Bharani" },
  { id: 3, name: "Karthikai" },
  { id: 4, name: "Rohini" },
  { id: 5, name: "Mirukasrisham" },
  { id: 6, name: "Thiruvathirai" },
  { id: 7, name: "Punarpoosam" },
  { id: 8, name: "Poosam" },
  { id: 9, name: "Ayilyam" },
  { id: 10, name: "Makam" },
  { id: 11, name: "Pooram" },
  { id: 12, name: "Uthiram" },
  { id: 13, name: "Astham" },
  { id: 14, name: "Chithirai" },
  { id: 15, name: "Suvathi" },
  { id: 16, name: "Visagam" },
  { id: 17, name: "Anusam" },
  { id: 18, name: "Keattai" },
  { id: 19, name: "Moolam" },
  { id: 20, name: "Pooradam" },
  { id: 21, name: "Uthiradam" },
  { id: 22, name: "Thiruvonam" },
  { id: 23, name: "Avittam" },
  { id: 24, name: "Sadhayam" },
  { id: 25, name: "Poorattathi" },
  { id: 26, name: "Uthirattathi" },
  { id: 27, name: "Revathi" },
  ]
};

const Rasi = {
    options:[
    { id: 1, name: "Mesham" },
  { id: 2, name: "Rishabam" },
  { id: 3, name: "Mithunam" },
  { id: 4, name: "Kadakam" },
  { id: 5, name: "Simmam" },
  { id: 6, name: "Kanni" },
  { id: 7, name: "Thulam" },
  { id: 8, name: "Viruchigam" },
  { id: 9, name: "Dhanusu" },
  { id: 10, name: "Makaram" },
  { id: 11, name: "Kumbam" },
  { id: 12, name: "Meenam" },
  ]
};


const Planet = {
    options:[
    { id: 1, name: "So" },
    { id: 2, name: "Sa" },
    { id: 3, name: "Ch" },
    { id: 4, name: "Bh" },
    { id: 5, name: "Gu" },
    { id: 6, name: "Suk" },
    { id: 7, name: "Sani" },
    { id: 8, name: "Rahu" },
    { id: 9, name: "Ke" },
    { id: 10, name: "Lagnam" },
  ]
};

const Planets = {
    options:[
    { value: 1, label: "So" },
    { value: 2, label: "Sa" },
    { value: 3, label: "Ch" },
    { value: 4, label: "Bh" },
    { value: 5, label: "Gu" },
    { value: 6, label: "Suk" },
    { value: 7, label: "Sani" },
    { value: 8, label: "Rahu" },
    { value: 9, label: "Ke" },
    { value: 10,label: "Lagnam" },
]
};

const imageURL = [
    "../images/1-orginal.jpg",
    "../images/5-orginal.jpg",
    "../images/3-orginal.jpg"
];

export default { ProfileList, Stars, Rasi, Planet, Planets, imageURL };
