import React, { useRef, useState } from 'react';
import "../css/Otpverify.css";

const Otpverify = () => {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [message, setMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const inputs = useRef([]);

    const handleChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        if (value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < 5) {
                inputs.current[index + 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text').slice(0, 6);
        const newOtp = [...otp];
        paste.split('').forEach((char, index) => {
            if (index < 6) {
                newOtp[index] = char;
            }
        });
        setOtp(newOtp);

        const nextEmptyInputIndex = newOtp.findIndex((char) => char === '');
        if (nextEmptyInputIndex !== -1) {
            inputs.current[nextEmptyInputIndex].focus();
        } else {
            inputs.current[5].focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const otpValue = otp.join('');
        if (otpValue === '123456') { // replace '123456' with the actual OTP you want to verify
            setMessage('OTP verified successfully!');
        } else {
            setMessage('Invalid OTP. Please try again.');
        }
        setShowPopup(true);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputs.current[index - 1].focus();
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
            <div className="otp-container">
                <h2>Enter OTP Code</h2>
                <form onSubmit={handleSubmit} className="otp-form">
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={handlePaste}
                            ref={(el) => inputs.current[index] = el}
                            maxLength="1"
                            className="otp-input"
                        />
                    ))}
                </form>
                <button type="submit" className="otp-button" onClick={handleSubmit}>Verify OTP</button>
                <div>
                    <p>
                        <a style={{ float: "right" }} href="#">
                            Resend OTP
                        </a>
                    </p>
                </div>
                {showPopup && (
                    <div className="otp-popup">
                        <div className="otp-popup-content">
                            <span className="otp-popup-close" onClick={handleClosePopup}>&times;</span>
                            <p>{message}</p>
                        </div>
                    </div>
                )}
            </div>
    );
};

export default Otpverify;
