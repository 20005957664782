import React from "react";
import { brandname } from "../config";
import Layout from "../core/Layout";
export default function About() {
  return (
    <Layout>
      <div>
        <h3>About Us</h3>
        <h5>Our Mission</h5>
        <p>
          At { brandname }, our mission is to bring together hearts and
          create meaningful connections that lead to lifelong partnerships. We
          believe that marriage is a sacred bond, and we are dedicated to
          helping you find your perfect match in a way that aligns with your
          values, traditions, and aspirations.
        </p>
        <h5>Our Story</h5>
        <p>
          { brandname } was founded in 2024 with the vision of
          providing a reliable, efficient, and culturally sensitive platform for
          individuals seeking marriage partners. Our journey began with a small
          team of passionate individuals who understood the importance of
          family, tradition, and love in Indian culture. Today, we have grown
          into one of the most trusted and comprehensive matrimony services,
          serving millions of users worldwide.
        </p>
        <h5>What We Offer</h5>
        <p>
          <strong>1. Extensive Profiles:</strong> We provide detailed and
          verified profiles to help you find someone who matches your
          preferences and values.
        </p>
        <p>
          <strong>2. Personalized Matches:</strong> Our advanced algorithms and
          personalized matchmaking services ensure that you receive the best
          possible matches based on your unique criteria.
        </p>
        <p>
          <strong>3. Privacy and Security:</strong> Your privacy and security
          are our top priorities. We have robust measures in place to protect
          your personal information and ensure a safe online experience.
        </p>
        <p>
          <strong>4. User-Friendly Interface:</strong> Our platform is designed
          to be intuitive and user-friendly, making it easy for you to navigate
          and find potential partners.
        </p>
        <p>
          <strong>5. Dedicated Support:</strong> Our customer support team is
          always ready to assist you with any questions or concerns, ensuring a
          smooth and enjoyable experience.
        </p>
        <h5>Our Values</h5>
        <p>
          <strong>Integrity:</strong> We operate with honesty and transparency,
          ensuring that you can trust the information and services we provide.
        </p>
        <p>
          <strong>Respect:</strong> We respect your privacy, your choices, and
          the cultural diversity of our users.
        </p>
        <p>
          <strong>Commitment:</strong> We are committed to helping you find a
          compatible partner and building a successful marriage.
        </p>
        <p>
          <strong>Innovation:</strong> We continually innovate and improve our
          platform to provide the best possible experience for our users.
        </p>
        <h5>Join Us</h5>
        <p>
          Join the { brandname } community today and take the
          first step towards finding your perfect life partner. Whether you're
          looking for someone who shares your faith, your language, or your
          lifestyle, we are here to help you on your journey to love and
          happiness.
        </p>
      </div>
    </Layout>
  );
}
