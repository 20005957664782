export const toggleSignin = () => {
    try{
      
      document.getElementById("register-toggle").style.backgroundColor = "transparent";
      document.getElementById("register-toggle").style.color = "#fff";
      document.getElementById("login-toggle").style.backgroundColor = "#a0ac60";
      document.getElementById("login-toggle").style.color = "#fff";
      document.getElementById("register-form").style.display = "none";
      document.getElementById("signin-form").style.display = "block";
    
      // document.getElementById("login-svg1").setAttribute("fill", "#fff");
      document.getElementById("login-svg").setAttribute("fill", "#fff");
      document.getElementById("register-svg").setAttribute("fill", "#fff");
      console.log('sigin')
    }catch(ex){}
  };
  
export const toggleRegister = () => {
  try{
      document.getElementById("register-toggle").style.backgroundColor = "#a0ac60";
      document.getElementById("register-toggle").style.color = "#fff";
      document.getElementById("login-toggle").style.backgroundColor = "transparent";
      document.getElementById("login-toggle").style.color = "#fff";
      document.getElementById("signin-form").style.display = "none";
      document.getElementById("register-form").style.display = "block";
    
      // document.getElementById("login-svg1").setAttribute("fill", "#fff");
      document.getElementById("login-svg").setAttribute("fill", "#fff");
      document.getElementById("register-svg").setAttribute("fill", "#fff");
    }catch(ex){}
  };
