import LoadingOverlay from 'react-loading-overlay';
import { useLoadingContext } from './LoadingContext';

function LoadingComponent(){
    const { loading} = useLoadingContext();
    return (
        <LoadingOverlay
            active={loading.loading}
            spinner
            text={!loading.message?'Loading.....':loading.message}
            className="loader" style={{position:"unset !important"}}>
        </LoadingOverlay>
    );
}


export default LoadingComponent;