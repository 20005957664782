import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { api } from '../../config';

export const fetchDataFromBackend = () => {
    return fetch(`${api}/get-all-data`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            console.error("Fetching data failed", response);
            throw response;
        }
    })
    .catch(error => {
        console.error('Error fetching data', error);
        throw error;
    });
};

// 2. Set Master Data Function
export const setMasterData = (data) => {
    secureLocalStorage.setItem('dropdownData', JSON.stringify(data));
};

// 3. get Master Data Function
export const getMasterData = async () => {
    const existingData = secureLocalStorage.getItem('dropdownData');

    if (existingData) {
        return JSON.parse(existingData);
    } else {
        try {
            const data = await fetchDataFromBackend();
            setMasterData(data);
            return data;
        } catch (error) {
            console.error('Error in fetching and setting master data', error);
            return null;
        }
    }
};

// Custom Hook
export const useDropdownData = () => {
    const [dropdownData, setDropdownData] = useState(null);

    useEffect(() => {
        getMasterData().then(fetchedData => {
            if (fetchedData) {
                setDropdownData(fetchedData);
            }
        });
    }, []);

    return dropdownData;
};
