
const FooterPage = () => {
  return (
    // <MDBFooter className="font-small lighten-5 pt-0 position-absolute w-100" style={{ left: '0px'}}>
    //   <div className="text-center py-3">
    //     <MDBContainer fluid>
    //       &copy; {new Date().getFullYear()} Copyright:{" "}
    //       <a href="https://koreka.in"> www.koreka.in</a>
    //     </MDBContainer>
    //   </div>
    // </MDBFooter>

    <div style={{position:"relative"}}>
      <div className="footer font-small lighten-5 pt-0 w-100">
          <div className="text-center py-3">
              &copy; 2024 - www.koreka.in | <a>Privacy Policy</a>
          </div>
      </div>
    </div>
  );
}

export default FooterPage;