import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { toggleRegister, toggleSignin } from "../core/AuthConfig";
import { useErrorContext } from "../core/ErrorContext";
import { useLoadingContext } from "../core/LoadingContext";
import { authenticate, getProfile, isAuthenticated, logout, register } from "../core/services/AuthService";
import User from "../model/User";
import { redirectHome } from "./services/RedirectService";

const UserContext = createContext(undefined);
export function UserProvider({ children }) {
    const { error, setError } = useErrorContext();
    const { loading, setLoading } = useLoadingContext();
    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);
    const [gUser, setGUser] = useState();
    var { Email, Password, AccountType, ProfileImage, FullName, redirectToReferrer } = User;

    
    const [user, setUser] = useState();
    
    const [profile, setProfile] = useState();

    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const googleLogin = useGoogleLogin({
    //     onSuccess: (codeResponse) => {
    //     setUser(codeResponse);
    //     },
    //     onError: (error) => console.log("Login Failed:", error),
    // });

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setGUser(codeResponse);
        },
        onError: (error) => {
        console.log("Login Failed:", error)
        setError(error);
        },
    });

    const toggleAuth = (history, path) => {
        if (history.location.pathname === "/signin") {
            toggleSignin();
        } else if (history.location.pathname === "/signup") {
            toggleRegister();
        }
    };

    // const logOut = () => {
    //     console.log("logout called");
    //     googleLogout();
    //     logout();
    //     setProfile(undefined);
    //     setUser(User);
    // };
    
    useEffect(() => {
        if(profile == undefined || profile == null){
            setProfile(getProfile());
        }
        if (gUser && !isAuthenticated()) {
            axios
            .get(
                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gUser.access_token}`,
                {
                    headers: {
                    Authorization: `Bearer ${gUser.access_token}`,
                    Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                setLoading({loading:true, message:"Login Progress....."});
                setUser({ ...user, error: "", loading: true, Email: res.data.email, Password: "", AccountType: "google", FullName: res.data.name, ProfileImage: res.data.picture});
                Email = res.data.email;
                Password = "";
                AccountType = "google";
                FullName = res.data.name;
                ProfileImage = res.data.picture;
                register({Email, Password, AccountType, FullName, ProfileImage}).then(data => { //{Email, Password, AccountType, FullName, ProfileImage} //{Email: user.Email, Password: user.Password, AccountType: user.AccountType, FullName: user.FullName, ProfileImage: user.ProfileImage}
                    if ((data.error || !data.token)) {
                        setError(data.error);
                        setUser({ ...user, error: data.error, loading: false });
                        setLoading({loading:false, message:"Login Failed....."});
                    } else {
                        authenticate(data, () => {
                          // profile.Email = data.Email;
                          // profile.FullName = data.FullName;
                          // profile.ProfileImage = data.ProfileImage;
                          // profile.AccountType = "google";
                            setProfile({
                                Email: data.Email,
                                FullName: data.FullName,
                                ProfileImage: data.ProfileImage,
                                AccountType: data.AccountType
                            });
                            setUser({
                                ...user,
                                redirectToReferrer: true
                                , loading: false
                            });
                            setLoading({loading:false, message:"Login Success....."});
                            setGUser(undefined);
                            // redirectUser();
                            redirectHome(true);
                        });
                    }
                });
            })
            .catch(
                (err) => {
                    setError(err);
                    // setIsAuthenticated(false);
                    setLoading({loading:false, message:"Login Failed....."});
                    setUser({
                        ...user,
                        redirectToReferrer: false , loading: false
                    });
                    setGUser(undefined);
                }
            );
        }
    }, [gUser]); //gUser
    
    // const redirectUser = () => {
    //     // if (redirectToReferrer) {
    //     //     if (user ) {
    //     //         return <Redirect to="/" />;
    //     //     }
    //     // }
    //     if (isAuthenticated()) {
    //         window.location.href = "/";
    //         // return <Redirect to="/" />;
    //     }
    // };

    const logOut = () => {
        googleLogout();
        logout();
        setProfile(undefined);
        setUser(undefined);
        setLoading({loading:false, message:"Logout Success....."});
        setUser(null);
    };


    // var { Email, Password, AccountType, ProfileImage, FullName, loading, error, redirectToReferrer } = user;

    //, isAuthenticated, setIsAuthenticated
    return (
        <UserContext.Provider value={{user, setUser, profile, setProfile, googleLogin, logOut, toggleAuth}}>{children}</UserContext.Provider>
    );
}

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within UserProvider");
    }
    return context;
}