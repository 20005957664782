import secureLocalStorage from "react-secure-storage";
import { api } from "../../config";
export const login = (userData) => {
    
    return fetch(`${api}/login`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
            // authenticate(data, () => {
            //     setValues({
            //         ...values,
            //         redirectToReferrer: true
            //     });
            // });
        } else {
            console.error("Login failed", response);
            throw response;
        }
    })
    .catch(err => {
        console.log(err);
        throw err;
    });
};

export const register = (userData) => {
    return fetch(`${api}/register`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
    })
    .then(response => {
        // console.log(response);
        if (response.ok) {
            return response.json();
            // authenticate(data, () => {
            //     setValues({
            //         ...values,
            //         redirectToReferrer: true
            //     });
            // });
        } else {
            throw response;
        }
    })
    .catch(err => {
        throw err;
    });
};

export const authenticate = (data, next) => {
    if (typeof window !== 'undefined') {
        secureLocalStorage.setItem("token", JSON.stringify(data.token));
        secureLocalStorage.setItem("ud", JSON.stringify(data));
        secureLocalStorage.setItem("auth", true);
        // localStorage.setItem('token', JSON.stringify(data.token));
        // sessionStorage.setItem('ud', JSON.stringify(data));
        next();
    }
};

export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    if (secureLocalStorage.getItem('auth')) {
        return secureLocalStorage.getItem('auth');
    } else {
        return false;
    }
};

export const getJwt = () => {
    if (typeof window == 'undefined') {
        return undefined;
    }
    if (secureLocalStorage.getItem('token')) {
        return JSON.parse(secureLocalStorage.getItem('token'));
    } else {
        return undefined;
    }
};

export const getProfile = () =>{
    if (secureLocalStorage.getItem('ud')) {
        return JSON.parse(secureLocalStorage.getItem('ud'));
    } else {
        return undefined;
    }
}

export const logout = next => {
    if (typeof window !== 'undefined') {
        secureLocalStorage.removeItem('token');
        secureLocalStorage.removeItem('ud');
        secureLocalStorage.removeItem("auth");
        // next();
        // return fetch(`${api}/signout`, {
        //     method: 'GET'
        // })
        //     .then(response => {
        //         console.log('signout', response);
        //         alert("you have been signed out successfuly !!!");
        //     })
        //     .catch(err => console.log(err));
    }
};