import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Otpverify from './pages/Otpverify';
import Search from './pages/Search';
import Signin from './pages/Signin';
import profiledetail from './pages/Profiledetail';
const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/search" exact component={Search} />
                <Route path="/signup" exact component={Signin} />
                <Route path="/signin" exact component={Signin} />
                <Route path="/about" exact component={About} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/Otpverify" exact component={Otpverify} />
                <Route path="/profiledetail" exact component={profiledetail} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
