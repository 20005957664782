// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import React from "react";
import '../styles.css';
import Footer from './FooterPage';
import Header from './Header';


const Layout = ({
    title = "Title",
    description = "Description",
    className = "container",
    children
}) => (

    <div>
        <Header />
        <div className={className}>{children}</div>
        <Footer />
    </div>
);

export default Layout;
