import React from "react";
import { Link } from "react-router-dom";
import { UserIcon } from "../icons/Icons";
import { useUserContext } from './UserContext';
function UserComponent(history) {
    const { profile, logOut } = useUserContext();
    // console.log(profile)
    return (  //(history, path, {profile}) => //(profile) =>
    
        profile?
    
            <ul className="nav navbar-nav navbar-right">
                <li className="loginProfile dropdown">
                    <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    >
                    <span>{(profile.FullName?profile.FullName:profile.Email)}</span>&nbsp;&nbsp;
                    {profile.ProfileImage?<img src={profile.ProfileImage} alt="user image" />:<UserIcon/>}
                    
                    <i></i>
                    </a>
                    <ul className="dropdown-menu" style={{width: "100%",marginTop:"150px", textAlign:"center"}}>
                    <li>
                        <Link onClick={logOut}>
                            <i className="fa fa-sign-out"></i>&nbsp;Logout
                        </Link>
                    </li>
                    </ul>
                </li>
            </ul>: ""
    
    );
}
export default UserComponent;
