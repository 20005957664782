import Layout from "../core/Layout";
export default function Contact() {
  // const { loading, setLoading } = useLoadingContext();

  // useEffect(() => {
  //     console.log("setloading");
  //     setLoading({loading:true, message:"Searching....."});
  // }, []);

  return (
    <Layout>
      <div>
        <h3>Contact Us</h3>
        <p>
          We are here to help you with any questions or concerns you may have.
          Feel free to reach out to us through any of the following methods, and
          our dedicated support team will be happy to assist you.
        </p>
        <h5>Customer Support</h5>
        <p>
          <strong>Phone:</strong> +91-XXXXXXXXXX
          <br />
          <strong>Email:</strong> support@koreka.in
          <br />
          <strong>Working Hours:</strong> Monday to Saturday, 9:00 AM to 6:00 PM
          IST
        </p>
        <h5>Office Address</h5>
        <p>
          <strong>Head Office:</strong>
          <br />
          Koreka Technologies Solutions
          <br />
          75/A2, Tanisha Arcade,
          <br />
          Kumbakonam Road(North Part)
          <br />
          Jayankondam, Ariyalur - 621 802
          <br />
          Tamilnadu, India.
        </p>
        <h5>Feedback and Suggestions</h5>
        <p>
          We value your feedback and suggestions. If you have any ideas on how
          we can improve our services, please let us know.
        </p>
        <p>
          <strong>Email:</strong> feedback@koreka.in
        </p>
        <h5>Social Media</h5>
        <p>
          Stay connected with us on social media for the latest updates and
          news.
        </p>
        <p>
          <strong>Facebook:</strong> <a href="https://www.facebook.com/korekasolution" target="_blank">korekasolution</a>
          <br />
          <strong>Instagram:</strong> <a href="https://www.instagram.com/koreka_solution/" target="_blank">koreka_solution</a>
          <br />
          <strong>LinkedIn:</strong> <a href="https://www.linkedin.com/company/korekatech" target="_blank">korekatech</a>
        </p>
        <h5>Online Support</h5>
        <p>
          For immediate assistance, you can also use our online support chat
          available on our website. Simply click on the chat icon at the bottom
          right corner of the screen to start a conversation with one of our
          representatives.
        </p>
        <h5>Frequently Asked Questions (FAQs)</h5>
        <p>
          Visit our{" "}
          <a rel="noreferrer" href="#">
            FAQ page
          </a>{" "}
          for quick answers to common questions about our services.
        </p>
      </div>
    </Layout>
  );
}
