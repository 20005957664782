import { CloseIcon } from "../icons/Icons";
import { useErrorContext } from './ErrorContext';
function ErrorComponent(){
    const { error } = useErrorContext();
    // function close(obj){
    //     $(obj).closest("#error").remove();
    // }
    return (
        error?
        (
            <div className="error">
                {
                    error.map(txt => (
                        <div className="alert alert-danger"><p>{txt}</p><a><CloseIcon width="12px" height="12px"/></a></div>)
                    )
                }
            </div>
        ):""
    );
}


export default ErrorComponent;