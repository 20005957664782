import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import Layout from "../core/Layout";
import { useDropdownData } from '../core/services/MasterDataService';
import '../css/home.css';
import ProfileList from "../data/ProfileList";

$(document).ready(function(){
    $('.slide-effect').mouseleave(function(){
        $(this).removeClass('clicked');
    }).click(function(){
        $(this).addClass('clicked').html($(this).html());
    });
});

export default function Home() {
    const [data1, setData] = useState({imageData:ProfileList.imageURL})
    const [loading, setLoading] = useState({loading: false, message: "Loading..."});
    const initialized = useRef(false);

    // Fetch dropdown data
    const dropdownData = useDropdownData();
    
    const images = [
        {
            thumbnail: "../images/2.jpg",
            original: "../images/2-orginal.jpg",
        },
        {
            thumbnail: "../images/4.jpg",
            original: "../images/4-orginal.jpg",
        },
        {
            thumbnail: "../images/1.jpg",
            original: "../images/1-orginal.jpg",
        },
    ];
    const images1 = [
        {
            thumbnail: "../images/1.jpg",
            original: "../images/1-orginal.jpg",
        },
        {
            thumbnail: "../images/2.jpg",
            original: "../images/2-orginal.jpg",
        },
        {
            thumbnail: "../images/5.jpg",
            original: "../images/5-orginal.jpg",
        },
    ];
    const renderImages = (side) => {
        var tmp = (side == 1? images: images1);
        return tmp.map((image, index) => (
            <img
                key={index}
                src={image.thumbnail}
                style={{
                    objectFit: 'cover',
                }}
            />
        ));
    };

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            setLoading(loading);
        }
    }, [loading]);

    // useEffect(() => {
    //     if (dropdownData) {
    //         console.log("Dropdown data:", dropdownData);
    //         // Perform any actions needed with the dropdown data
    //     }
    // }, [dropdownData]);

    return (
        <Layout className="container no-bg align-items-unset justify-content-unset">
            <div className="col-lg-2 gallery left d-none d-lg-grid">
                {renderImages(1)}
            </div>
            <div className="col-lg-6 ml-auto mr-auto" style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                <div style={{ width: "300px", height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div id="home" className="home-content text-center slide-effect">
                        <p className='text'>Find your best</p>
                        <h1 className='slideDown'>Life Partner</h1>
                        <p className='slideUp'>with our matrimony service</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 gallery right d-none d-lg-grid">
                {renderImages(2)}
            </div>
        </Layout>
    );
}
