import { createContext, useContext, useState } from "react";

const ErrorContext = createContext();

export function ErrorProvider({ children }) {
    const [error, setError] = useState();
    return (
        <ErrorContext.Provider value={{error, setError}}>{children}</ErrorContext.Provider>
    );
}

export function useErrorContext() {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error("useError must be used within ErrorProvider");
    }
    return context;
}