import { createContext, useContext, useState } from "react";

const LoadingContext = createContext();

export function LoadingProvider({ children }) {
    const [loading, setLoading] = useState({loading:false, message:"Loading..."});
    return (
        <LoadingContext.Provider value={{loading, setLoading}}>{children}</LoadingContext.Provider>
    );
}

export function useLoadingContext() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}