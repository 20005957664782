import $ from "jquery";
import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useRef, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaCamera } from 'react-icons/fa';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import ImageGallery from "react-image-gallery";
import { MultiSelect } from "react-multi-select-component";
import { getMasterData } from '../core/services/MasterDataService';
import '../css/profiledetail.css';

$('body').on('keydown', 'input, select', function(e) {
  if (e.which === 13) {
    var self = $(this), form = self.parents('form:eq(0)'), focusable, next;
    focusable = form.find('input').filter(':visible');
    next = focusable.eq(focusable.index(this)+1);
    if (next.length) {
        next.focus();
    }
    return false;
  }
});

$(document).ready(function(){

  console.log("loaded");
  // (function( func ) {
  //   $.fn.addClass = function() { // replace the existing function on $.fn
  //       func.apply( this, arguments ); // invoke the original function
  //       this.trigger('classChanged'); // trigger the custom event
  //       return this; // retain jQuery chainability
  //   }
  // })($.fn.addClass); // pass the original function as an argument

  // (function( func ) {
  //   $.fn.removeClass = function() {
  //       func.apply( this, arguments );
  //       this.trigger('classChanged');
  //       return this;
  //   }
  // })($.fn.removeClass);



  $("#matchingStars_input").on('change', function(){ 
    
    if($(this).hasClass("displayBlock")){
      console.log(1)
      if($(document).height() - $(this).parent().position().top <= 250){
        console.log(2)
        var pos = $(this).parent().position().top - $(this).height() - 100;
        $(this).position().top(pos);
      }
    }

  });
});
// const star = {
//   options: [{name: 'Option 1️⃣', id: 1},{name: 'Option 2️⃣', id: 2}]
// };

const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      {/* <img src={src || 'https://via.placeholder.com/150'} alt="Profile" /> */}
      
        <div className="image-previews">
        <ImageGallery items={src} showThumbnails={false} showPlayButton={false}/>
      </div>
      <FaCamera className="camera-icon" />
    </div>
    <input id="photo-upload" type="file" multiple onChange={onChange} />
  </label>
);

// Component for image slideshow
const ImageSlideshow = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="slideshow-container">
      {images.length > 0 && (
        <>
          <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
          <button className="slideshow-button prev" onClick={goToPrevious}>
            <MdArrowBack />
          </button>
          <button className="slideshow-button next" onClick={goToNext}>
            <MdArrowForward />
          </button>
        </>
      )}
    </div>
  );
};

const getDropdownDataFromLocalStorage = () => {
  const keys = ['rasi', 'stars']; // Add other keys as needed
  let localStorageData = {};

  keys.forEach(key => {
    const data = JSON.parse(localStorage.getItem(key));
    if (data) {
      localStorageData[key] = data;
    }
  });
console.log(localStorageData);
  return localStorageData;
};


const ProfileDetail = () => {
  
  const [profile, setProfile] = useState({
    about: 'about the profile',
    name: 'name',
    age: 'age',
    dob: 'dob',
    profession: 'profession',
    income: '10990',
    education: 'mba',
    height: '5.2',
    weight: '60',
    bodyType: 'slim',
    appearance: 'fair',
    clan: 'na',
    tribe: 'na',
    foodHabit: 'non-veg',
    religion: 'hindu',
    community: 'community',
    cast: 'cast',
    interest: 'music',
    nonInterest: '',
    fatherName: 'father name',
    fatherOccupation: 'farmer',
    fatherStatus: 'alive',
    motherName: 'mother name',
    motherOccupation: 'farmer',
    motherStatus: 'alive',
    brothers: '3 brother',
    brothersMarried: '2',
    sisters: '2 sister',
    sistersMarried: '2',
    movableAsset: '5L, 10 Pouvn Gold',
    immovableAsset: '2Acre Land',
    birthTime: '9:20',
    lagnam: '',
    star: '',
    dhasa: '',
    iruppu: '',
    tamilYear: '',
    month: '',
    R1: '',
    R2: '',
    R3: '',
    R4: '',
    R5: '',
    R6: '',
    R7: '',
    R8: '',
    R9: '',
    R10: '',
    R11: '',
    R12: '',
    A1: '',
    A2: '',
    A3: '',
    A4: '',
    A5: '',
    A6: '',
    A7: '',
    A8: '',
    A9: '',
    A10: '',
    A11: '',
    A12: '',
    images: [],
  });

  const [MasterData, setMasterDataOptions] = useState([]);
  const [rasiOptions, setRasiOptions] = useState([]);
  const [castOptions, setCastOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [expectationOptions, setExpectationOptions] = useState([]);
  const [planetsOptions, setPlanetsOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [starsOptions, setStarsOptions] = useState([]);
  const [stars1Options, setStars1Options] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [tamilMonthOptions, setTamilMonthOptions] = useState([]);
  const [tamilYearOptions, setTamilYearOptions] = useState([]);
  const [matchingStars, setMatchingStars] = useState([]);
  const [nonMatchingStars, setNonMatchingStars] = useState([]);

  const [r1, setR1] = useState([]);
  const [r2, setR2] = useState([]);
  const [r3, setR3] = useState([]);
  const [r4, setR4] = useState([]);
  const [r5, setR5] = useState([]);
  const [r6, setR6] = useState([]);
  const [r7, setR7] = useState([]);
  const [r8, setR8] = useState([]);
  const [r9, setR9] = useState([]);
  const [r10, setR10] = useState([]);
  const [r11, setR11] = useState([]);
  const [r12, setR12] = useState([]);

  const [a1, setA1] = useState([]);
  const [a2, setA2] = useState([]);
  const [a3, setA3] = useState([]);
  const [a4, setA4] = useState([]);
  const [a5, setA5] = useState([]);
  const [a6, setA6] = useState([]);
  const [a7, setA7] = useState([]);
  const [a8, setA8] = useState([]);
  const [a9, setA9] = useState([]);
  const [a10, setA10] = useState([]);
  const [a11, setA11] = useState([]);
  const [a12, setA12] = useState([]);

  const monetaryFieldRefs = {
    movableAsset: useRef(null),
    immovableAsset: useRef(null),
  };


  useEffect(() => {
    console.log("useEffect triggered");
  
    // Fetch dropdown data from local storage and set the options if MasterData is empty
    if (MasterData.length <= 0) {
      getMasterData()
        .then(response => {
          console.log(response);
          if (response) {
            setMasterDataOptions(response);
            setRasiOptions(response.rasi);
            setCastOptions(response.cast);
            setDistrictOptions(response.district);
            setEducationOptions(response.education);
            setExpectationOptions(response.expectation);
            setPlanetsOptions(response.planets);
            setProfessionOptions(response.profession);
            setReligionOptions(response.religion);
            setStarsOptions(response.stars);
            setStateOptions(response.state);
            setTamilMonthOptions(response.tamil_month);
            setTamilYearOptions(response.tamil_year);
  
            // Fetch and set stars1Options
            const data = response.stars;
            setStars1Options(data.map(option => ({
              id: option.Id,
              name: option.Desc
            })));
          }
        })
        .catch(error => {
          console.error('Error fetching master data:', error);
        });
    }
  }, []); 
  

  const mappedPlanetsOptions = planetsOptions.map(option => ({
    label: option.Desc, // Label to display in the dropdown
    value: option.Id    // Value to return when selected
  }));

  const handleFileChange = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    const updatedImages = files.map(file => URL.createObjectURL(file));
    setProfile(prevProfile => {
      const newImages = [ ...updatedImages];
      profile.images = [];
      const PI = [];
      // if (newImages.length > 3) {
      //   newImages.splice(0, newImages.length - 3);
      // }
      newImages.forEach(element => {
        PI.push({
          thumbnail:element,
          original:element
        });
      });
      return {
       images: PI,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check monetary fields
    if (!profile.movableAsset) {
      alert('Movable Asset is required');
      monetaryFieldRefs.movableAsset.current.focus();
      return;
    }
    if (!profile.immovableAsset) {
      alert('Immovable Asset is required');
      monetaryFieldRefs.immovableAsset.current.focus();
      return;
    }
    // Handle form submission logic here
    console.log(profile);
  };

  return (
    <div className="profile-detail">
    <div className="form-container">
    <div className="row">
      <div className="form-group-container col-lg-3 col-md-4 col-12" style={{textAlign:"center"}}>
        <ImgUpload
          onChange={handleFileChange}
          src={profile.images.length > 0 ? profile.images : [{ original: 'https://via.placeholder.com/150', thumbnail: 'https://via.placeholder.com/150' }]}
        />
      </div>

      <div className="basic-info-container col-lg-9">
      <h2>Basic Information</h2>
      <div className="row">
        {['name', 'age', 'dob', 'profession', 'income', 'education'].map((field) => (
          <div className="col-lg-4 col-md-3 col-12" key={field}>
            <div className="form-group mt-3" style={{ position: "relative" }}>
              <label className='floating'>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
              {field === 'education' || field === 'profession' ? (
                <select
                  name={field}
                  value={profile[field]}
                  onChange={handleChange}
                  placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                >
                  <option value="">Select</option>
                  {(field === 'education' ? educationOptions : professionOptions).map((option) => (
                    <option key={option.Id} value={option.Id}>
                      {option.Desc}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field === 'dob' ? 'date' : 'text'}
                  name={field}
                  value={profile[field]}
                  onChange={handleChange}
                  placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>

      </div>
    </div>

        <form onSubmit={handleSubmit}>
          
        <Tabs
      defaultActiveKey="myProfile"
      className="mb-3"
    >
      <Tab eventKey="myProfile" title="My Profile">

<section>
  <div className="row">
    <div className="col-lg-6 col-md-6 col-12">
      <h2>About the Profile</h2>
      <textarea
        type="text"
        name="about"
        value={profile.about}
        onChange={handleChange}
        placeholder="Write about profile expectations..."
        className="long-input"
      />
      <h2>Expectation</h2>
       <div className="form-group mt-3" style={{ position: "relative" }}>
                  <label className='floating'>Expectation</label>
                  <select
                    name="expectation"
                    value={profile.expectation}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {expectationOptions.map((option) => (
                      <option key={option.Id} value={option.Id}>
                        {option.Desc}
                      </option>
                    ))}
                  </select>
                </div>
    </div>

    <div className="col-lg-6 col-md-6 col-12">
      <h2>Personal Information</h2>
      <div className="row">
        {[' Address Line1',' Address Line2(optional)'].map((field) => (
          <div className="col-lg-6 col-md-6 col-12" key={field}>
            <div className="form-group mt-3" style={{ position: "relative" }}>
              <label className='floating'>{field.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + field.slice(1)}</label>
              <input
                type="text"
                name={field}
                value={profile[field]}
                onChange={handleChange}
                placeholder={field.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + field.slice(1)}
              />
            </div>
          </div>
        ))}

        <div className="col-lg-6 col-md-6 col-12">
          <div className="form-group mt-3" style={{ position: "relative" }}>
            <label className='floating'>State</label>
            <select
              name="state"
              value={profile.state}
              onChange={handleChange}
              placeholder="State"
            >
              <option value="">Select</option>
              {stateOptions.map((option) => (
                <option key={option.Id} value={option.Id}>
                  {option.Desc}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-12">
          <div className="form-group mt-3" style={{ position: "relative" }}>
            <label className='floating'>District</label>
            <select
              name="district"
              value={profile.district}
              onChange={handleChange}
              placeholder="District"
            >
              <option value="">Select</option>
              {districtOptions.map((option) => (
                <option key={option.Id} value={option.Id}>
                  {option.Desc}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-12">
          <div className="form-group mt-3" style={{ position: "relative" }}>
            <label className='floating'>coundry</label>
            <input
              type="text"
              name="coundry"
              value={profile.coundry}
              onChange={handleChange}
              placeholder="coundry"
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-12">
          <div className="form-group mt-3" style={{ position: "relative" }}>
            <label className='floating'>Pincode</label>
            <input
              type="text"
              name="pincode"
              value={profile.pincode}
              onChange={handleChange}
              placeholder="Pincode"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

              <section>
  <h2>Additional Information</h2>
  <div className="row">
    {['height', 'weight', 'bodyType', 'appearance', 'clan', 'tribe', 'foodHabit'].map((field) => (
      <div className="col-lg-3 col-md-4 col-12" key={field}>
        <div className="form-group mt-3" style={{position:"relative"}}>
          <label className='floating'>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
          <input
            type="text"
            name={field}
            value={profile[field]}
            onChange={handleChange}
            placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
          />
        </div>
      </div>
    ))}
    {['religion', 'cast'].map((field) => (
      <div className="col-lg-3 col-md-4 col-12" key={field}>
        <div className="form-group mt-3" style={{position:"relative"}}>
          <label className='floating'>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
          <select
            name={field}
            value={profile[field]}
            onChange={handleChange}
            placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
          >
            <option value="">Select</option>
            {(field === 'religion' ? religionOptions : castOptions).map((option) => (
              <option key={option.Id} value={option.Id}>
                {option.Desc}
              </option>
            ))}
          </select>
        </div>
      </div>
    ))}
  </div>
</section>

              <section>
                <h2>Family Detail</h2>
                <div className="row">
                  {[
                    'fatherName',
                    'fatherOccupation',
                    'fatherStatus',
                    'motherName',
                    'motherOccupation',
                    'motherStatus',
                    'brothers',
                    'brothersMarried',
                    'sisters',
                    'sistersMarried',
                  ].map((field) => (
                    <div className="col-lg-3 col-md-4 col-12" key={field}>
                      <div className="form-group mt-3" style={{position:"relative"}}>
                      <label className='floating'>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                        <input
                          type="text"
                          name={field}
                          value={profile[field]}
                          onChange={handleChange}
                          placeholder={
                            field
                              .replace(/([A-Z])/g, ' $1')
                              .replace(/^./, (str) => str.toUpperCase())
                              .trim()
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </section>

              <section>
                <h2>Monetary Information</h2>
                <div className="row">
                  {['movableAsset', 'immovableAsset'].map((field) => (
                    <div className="col-lg-6 col-md-6 col-12" key={field}>
                      <div className="form-group mt-3" style={{position:"relative"}}>
                      <label className='floating'>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                        <input
                          type="text"
                          name={field}
                          value={profile[field]}
                          onChange={handleChange}
                          ref={monetaryFieldRefs[field]}
                          placeholder={
                            field
                              .replace(/([A-Z])/g, ' $1')
                              .replace(/^./, (str) => str.toUpperCase())
                              .trim()
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </section>

              <section>

              <h2>Horoscope Information</h2>
              <div className="row">
             {['birthTime', 'lagnam', 'star', 'dhasa' , 'iruppu', 'tamilYear', 'tamilmonth'].map((field) => (
              <div className="col-lg-3 col-md-4 col-12" key={field}>
               <div className="form-group mt-3" style={{position:"relative"}}>
               <label className='floating'>
               {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
               </label>
               {(field === 'lagnam' || field === 'star' || field === 'dhasa' || field === 'tamilYear' || field === 'tamilmonth') ? (
               <select
              name={field}
              value={profile[field]}
              onChange={handleChange}
              placeholder={
                field
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str) => str.toUpperCase())
                  .trim()
              }
            >
              <option value="">Select</option>
              {(field === 'lagnam'
                ? rasiOptions
                : field === 'star'
                ? starsOptions
                : field === 'dhasa'
                ? planetsOptions
                : field === 'tamilYear'
                ? tamilYearOptions
                : tamilMonthOptions
              ).map((option) => (
                <option key={option.Id} value={option.Id}>
                  {option.Desc}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              name={field}
              value={profile[field]}
              onChange={handleChange}
              placeholder={
                field
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str) => str.toUpperCase())
                  .trim()
              }
            />
          )}
        </div>
      </div>
    ))}
  </div>
              
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <h2>Rasi Kattam</h2>
                    <table className="rasi-table">
                        <tbody>
                        <tr>
                        <td>
                        <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r12}
                                onChange={setR12}
                                labelledBy="Select Planets"
                                id="R12"
                          />
                      </td>
                      <td>

                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r1}
                                onChange={setR1}
                                labelledBy="Select"
                                id="R1"
                              />
                          </td>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r2}
                                onChange={setR2}
                                labelledBy="Select"
                                id="R2"
                              />
                          </td>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r3}
                                onChange={setR3}
                                labelledBy="Select"
                                id="R3"
                              />
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r11}
                                onChange={setR11}
                                labelledBy="Select"
                                id="R11"
                              />
                          </td>
                          <td colspan="2" rowspan="2">Rasi Kattam</td>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r4}
                                onChange={setR4}
                                labelledBy="Select"
                                id="R4"
                              />
                            </td>
                        </tr>
                        <tr>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r10}
                                onChange={setR10}
                                labelledBy="Select"
                                id="R10"
                              />
                          </td>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r5}
                                onChange={setR5}
                                labelledBy="Select"
                                id="R5"
                              />
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r9}
                                onChange={setR9}
                                labelledBy="Select"
                                id="R9"
                              />
                          </td>
                          <td>
                          <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r8}
                                onChange={setR8}
                                labelledBy="Select"
                                id="R8"
                              />
                          </td>
                          <td>
                            <MultiSelect
                                  options={mappedPlanetsOptions}
                                  value={r7}
                                  onChange={setR7}
                                  labelledBy="Select"
                                  id="R7"
                                />
                          </td>
                          <td>
                            <MultiSelect
                                options={mappedPlanetsOptions}
                                value={r6}
                                onChange={setR6}
                                labelledBy="Select"
                                id="R6"
                              />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <h2>Amsa Kattam</h2>
                    <table className="amsa-table">
                      <tbody>
                        <tr>
                          <td>
                          <MultiSelect
                              options={mappedPlanetsOptions}
                              value={a12}
                              onChange={setA12}
                              labelledBy="Select"
                              id="A12"
                            />
                          </td>
                          <td>
                            <MultiSelect
                              options={mappedPlanetsOptions}
                              value={a1}
                              onChange={setA1}
                              labelledBy="Select"
                              id="A1"
                            />
                          </td>
                          <td>
                            <MultiSelect
                              options={mappedPlanetsOptions}
                              value={a2}
                              onChange={setA2}
                              labelledBy="Select"
                              id="A2"
                            />
                          </td>
                          <td>
                            <MultiSelect
                              options={mappedPlanetsOptions}
                              value={a3}
                              onChange={setA3}
                              labelledBy="Select"
                              id="A3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <MultiSelect
                              options={mappedPlanetsOptions}
                              value={a11}
                              onChange={setA11}
                              labelledBy="Select"
                              id="A11"
                            />
                          </td>
                          <td colspan="2" rowspan="2">Amsa Kattam</td>
                          <td>
                              <MultiSelect
                                options={mappedPlanetsOptions}
                                value={a4}
                                onChange={setA4}
                                labelledBy="Select"
                                id="A4"
                              />
                            </td>
                        </tr>
                        <tr>
                          <td>
                              <MultiSelect
                                options={mappedPlanetsOptions}
                                value={a10}
                                onChange={setA10}
                                labelledBy="Select"
                                id="A10"
                              />
                          </td>
                          <td>
                              <MultiSelect
                                options={mappedPlanetsOptions}
                                value={a5}
                                onChange={setA5}
                                labelledBy="Select"
                                id="A5"
                              />
                          </td>
                        </tr>
                        <tr>
                          <td>
                              <MultiSelect
                                options={mappedPlanetsOptions}
                                value={a9}
                                onChange={setA9}
                                labelledBy="Select"
                                id="A9"
                              />
                          </td>
                          <td>
                              <MultiSelect
                                options={mappedPlanetsOptions}
                                value={a8}
                                onChange={setA8}
                                labelledBy="Select"
                                id="A8"
                              />
                          </td>
                          <td>
                            <MultiSelect
                              options={mappedPlanetsOptions}
                              value={a7}
                              onChange={setA7}
                              labelledBy="Select"
                              id="A7"
                            />
                          </td>
                          <td>
                              <MultiSelect
                                options={mappedPlanetsOptions}
                                value={a6}
                                onChange={setA6}
                                labelledBy="Select"
                                id="A6"
                              />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                </div>
              </section>
              
              <section>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <h2>Matching Stars</h2>
          <Multiselect
            options={stars1Options} // Options to display in the dropdown
            selectedValues={matchingStars} // Preselected value to persist in dropdown
            onSelect={setMatchingStars} // Function to handle selected options
            onRemove={setMatchingStars} // Function to handle removed options
            displayValue="name" // Property name to display in the dropdown options
            menuPlacement="top"
            id="matchingStars"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <h2>Non-Matching Stars</h2>
          <Multiselect
            options={stars1Options} // Options to display in the dropdown
            selectedValues={nonMatchingStars} // Preselected value to persist in dropdown
            onSelect={setNonMatchingStars} // Function to handle selected options
            onRemove={setNonMatchingStars} // Function to handle removed options
            displayValue="name" // Property name to display in the dropdown options
            menuPlacement="top"
            id="nonMatchingStars"
          />
        </div>
      </div>
    </section>

            </Tab>
            <Tab eventKey="partnerPreference" title="Partner Preference">
              Partner Preference
            </Tab>
          </Tabs>
          
          {/* <button type="submit" style={{marginBottom:"20px"}}>Submit</button> */}
         
        </form>
      </div>
      
  );
};

export default ProfileDetail;
