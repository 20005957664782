import $ from "jquery";
import React, { useState } from "react";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ImageGallery from "react-image-gallery";
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import Layout from "../core/Layout";
import '../css/ProfileDetails.css';
import "../css/search.css";
import ProfileList from "../data/ProfileList";
import { FilledLikeIcon, LikeIcon, ViewIcon } from "../icons/Icons";
import ProfileDetail from "./Profiledetail";


const profile = {
  Photo: '../images/1.jpg',
  Name: 'Name 1',
  Address: 'Address 1',
  AJITHKUMAR: 'Ajithkumar',
  Feb221997: 'Feb 22, 1997',
  Importent: 'Important',
  Kumbakonam: 'Kumbakonam',
  Leo: 'Leo',
  Magam: 'Magam',
  Height65: '6.5',
  Hindu: 'Hindu',
  hjhgjf: 'Sub-caste name',
  Tamil: 'Tamil',
  EducationDetails: 'Education details here',
  Income: 'Income details here',
  ProfessionalDetails: 'Professional details here',
  salary: 'Salary details here',
  l000000: '1000000',
  tottelmenbers: 'Total family members here'
};

function ProfileDetails({ profile, show, handleClose }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      fullscreen={true}
      keyboard={true}
      autoFocus={false}
      className="custom-modal modal-dialog-scrollable"
    >
      <Modal.Header closeButton>
        <Modal.Title>{profile.Name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProfileDetail></ProfileDetail>
      </Modal.Body>
      <Modal.Footer>
        
        <Button className="btn btn-primary" onClick={handleClose}>
          Save
        </Button>
        <Button className="btn btn-danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ProfileGallery({ profile, show, handleClose }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      fullscreen={false}
      keyboard={false}
      autoFocus={true}
      className="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="h6">{profile.Name}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:"0"}}>
          {/* <div>
            <img src={profile.PhotoOrg} alt={profile.Name} style={{width: "100%", height:"100%", objectFit:"cover"}} />
          </div> */}
          <ImageGallery items={profile.images} thumbnailPosition={"bottom"} showThumbnails={"false"} className="image"/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

class ProfileTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.gallery = {
      showGalleryModal: false,
    }
  }

  handleShow = () => this.setState({ showModal: true });
  handleClose = () => this.setState({ showModal: false });

  handleGalleryShow = () => this.setState({showGalleryModal: true});
  handleGalleryClose = () => this.setState({showGalleryModal: false});

  ChangeLike = (obj) => {
    console.log(obj);
    return obj.Like = !obj.Like;
  };

  render() {
    const { profile } = this.props;
    return (
      <div className="col-lg-2 col-md-3 col-12">
        <div className="single-profile mt-30">
          <div className="profile-img">
            <div>
              {/* <img src={profile.Photo} alt=""></img> */}
              <ImageGallery items={profile.images} showThumbnails={false} showPlayButton={false}/>
              {/* <a className="expand" style={{ background: "none" }} onClick={this.handleGalleryShow}><ExpandIcon fill="#ffffff" /></a> */}
              <a className="view" style={{ background: "none" }} onClick={this.handleShow}><ViewIcon fill="#ffffff" /></a>
              <a className="like" style={{ background: "none" }} onClick={() => this.ChangeLike(profile)}>
                {profile.Like ? <FilledLikeIcon fill="#ffffff" /> : <LikeIcon fill="#ffffff" />}
              </a>
              <div className="profile-desc">
                <h6>
                  <span>{profile.Name + " " + profile.Education + ""}</span>
                </h6>
                <div className="income-box">
                  <p>{"Income: " + profile.Income + "/m"}</p>
                </div>
              </div>
              <div className="watermark" style={{ fontSize: "14px" }}>
                Image Designed by @Freepik
              </div>
            </div>
            <span className="level-stryker">{profile.UserID}</span>
          </div>
        </div>
        {this.state.showModal && <ProfileDetails profile={profile} show={this.state.showModal} handleClose={this.handleClose} />}
        {this.state.showGalleryModal && <ProfileGallery profile={profile} show={this.state.showGalleryModal} handleClose={this.handleGalleryClose} />}
      </div>
    );
  }
}

class ProfileCatalog extends React.Component {
  renderTile = (current_item) => {
    return <ProfileTile profile={current_item} key={current_item.UserID} />;
  };

  render() {
    let tiles = [];
    for (let i = 0; i < this.props.profiles.length; i++) {
      const current_item = this.props.profiles[i];
      tiles.push(this.renderTile(current_item));
    }
    return tiles;
  }
}

export default function Search() {
  const [profiles, setProfile] = useState(ProfileList.ProfileList);
  const [currentPage, setCurrentPage] = useState(1);
  const [profilePerPage, setProfilePerPage] = useState(18);

  const totalPages = Math.ceil(profiles.length / profilePerPage);
  const startIndex = (currentPage - 1) * profilePerPage;
  const endIndex = startIndex + profilePerPage;
  const currentProfiles = profiles.slice(startIndex, endIndex);

  $(document).ready(function () {
    $(".profile-img a").click(function (e) {
      e.preventDefault();
      if ($(this).hasClass("like")) {
        console.log("like");
      } else if ($(this).hasClass("view")) {
        console.log("view");
      } else if ($(this).hasClass("expand")) {
        console.log("expand");
      }
    });
  });

  return (
    <Layout className="container">
      <div className="searchpage detail">
        <div className="row" style={{minHeight:"calc(100vh-150px)"}}>
          <ProfileCatalog
            profiles={currentProfiles}
            style={{ overflowY: "auto" }}
          />
        </div>
        <div className="row">
          <PaginationControl
            page={currentPage}
            between={4}
            total={profiles.length}
            limit={profilePerPage}
            changePage={(currentPage) => {
              setCurrentPage(currentPage);
            }}
            ellipsis={1}
          />
        </div>
      </div>
    </Layout>
  );
}
