//import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { toggleRegister, toggleSignin } from "../core/AuthConfig";
import { useErrorContext } from "../core/ErrorContext";
import { useLoadingContext } from "../core/LoadingContext";
import { useUserContext } from "../core/UserContext";
// import UserContext from "../core/UserContext";
// import { UserContext, UserProvider, useUser } from "../core/UserContext";
import "../css/header.css";
import {
  HamburgetMenuClose,
  HamburgetMenuOpen
} from "../icons/Icons";
import brandImg from "../images/brand.png";
import User from "../model/User";
import ErrorComponent from "./ErrorComponent";
import UserComponent from "./UserComponent";
const isActive = (history, path) => {
  if (history.location.pathname === path) {
    return "active";
  } else {
    return "";
  }
};

const toggleAuth = (history, path) => {
  if (history.location.pathname === "/signin") {
    toggleSignin();
  } else if (history.location.pathname === "/signup") {
    toggleRegister();
  }
};

function Header(history) {
  const { error, setError } = useErrorContext();
  const { loading, setLoading } = useLoadingContext();
  const { user, setUser, profile, setProfile, setIsAuthenticated, googleLogin } = useUserContext();
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const [gUser, setGUser] = useState();

  var { Email, Password, AccountType, ProfileImage, FullName, redirectToReferrer } = User;
  

  // const handleChange = name => event => {
  //     setUser({ ...user, error: "", [name]: event.target.value });
  // };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     setGUser(codeResponse);
  //   },
  //   onError: (error) => {
  //     console.log("Login Failed:", error)
  //     setError(error);
  //   },
  // });

  
  const header = () => (
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <div className="logoBg">
              <img src={brandImg} alt="brand"></img>
            </div>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/search"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Search
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact
              </NavLink>
            </li>
          </ul>
          { profile == null?
                  <div className="nav-auth">
                      <Link
                          value="SignIn"
                          className="signin"
                          to="/signin"
                          onClick={toggleAuth(history, "/signin")}
                      >
                          Signin
                      </Link>
                      <Link
                          value="SignUp"
                          className="signup"
                          to="/signup"
                          onClick={toggleAuth(history, "/signin")}
                      >
                          Signup
                      </Link>
                      {/* <span>&nbsp;&nbsp;Or</span>
                      <a className="signin" onClick={googleLogin} style={{color:"#4c5933 !important"}}>
                        <GoogleIcon width="12px" height="12px" />
                        &nbsp;Signin
                      </a> */}
                  </div>
                  :""
          } 
          <UserComponent value={profile}/>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuClose />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
      </nav>
  );


  return (
    <div>
        {header()}
        <ErrorComponent/>
        {/* {redirectUser()} */}
    </div>
);
}

export default withRouter(Header);
