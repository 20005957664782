import { GoogleOAuthProvider } from "@react-oauth/google";
import $ from "jquery";
import React from "react";
import { ErrorProvider } from "./core/ErrorContext";
import LoadingComponent from "./core/LoadingComponent";
import { LoadingProvider } from "./core/LoadingContext";
import { UserProvider } from "./core/UserContext";
import Routes from "./Routes";
function App() {

  $(document).ready(function() {
      $(window).scroll(function() {
          var distanceFromTop = $(document).scrollTop();
          if (distanceFromTop >= $('.navbar').height())
          {
              $('.error').addClass('fixed');
          }
          else
          {
              $('.error').removeClass('fixed');
          }
      });
      // $("body").addClass("body");
      $('.error .alert a').click(function(e) {
        $(this).closest("div").remove();
      });
  });
  return (
    <GoogleOAuthProvider clientId="10876417941-60ovnj7d2p8s42t44b316c786gq5a9ds.apps.googleusercontent.com">
      <LoadingProvider>
        <ErrorProvider>
          <UserProvider>
            <Routes />
            <LoadingComponent />
          </UserProvider>
        </ErrorProvider>
      </LoadingProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
