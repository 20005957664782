import $ from "jquery";
import { useHistory } from "react-router-dom";
import { toggleRegister, toggleSignin } from "../core/AuthConfig";
import { useErrorContext } from "../core/ErrorContext";
import Layout from "../core/Layout";
import { useLoadingContext } from "../core/LoadingContext";
import { authenticate, login, register } from "../core/services/AuthService";
import { useUserContext } from "../core/UserContext";
import "../css/signin.css";
import { GoogleIcon, RegisterIcon, SigninIcon } from "../icons/Icons";
function Signin() {
  const history = useHistory();

  const handleClick = () => {
      history.push("/");
  }
  const { loading, setLoading } = useLoadingContext();
  const { error, setError } = useErrorContext();
  // const [values, setValues] = useState({
  //       Email: "",
  //       Password: "",
  //       AccountType: "local",
  //       error: "",
  //       loading: false,
  //       redirectToReferrer: false
  //   });
    const { user, setUser, profile, setProfile, setIsAuthenticated, googleLogin } = useUserContext();
    

    // const handleChange = name => event => {
    //     setValues({ ...values, error: false, [name]: event.target.value });
    // };

    // const [UserId, setUserId] = useState(null);
    // const history = useHistory();

    console.log(history.location.pathname);
    $(document).ready(()=>{
      if (history.location.pathname === "/signin") {
        // toggleSignin();
        $("#login-toggle").trigger("click");
      } else if (history.location.pathname === "/signup") {
        // toggleRegister();
        $("#register-toggle").trigger("click");
      }
    });
    

  const handleLogin = event => {
    console.log("Attempting login...");

    event.preventDefault();
    const Email = document.getElementById("Email").value;
    const Password = document.getElementById("Password").value;
    const AccountType = "local";

    setUser({ ...user, error: "", loading: true, Email: Email, Password: "", AccountType: "local", FullName: "", ProfileImage: ""});
    setLoading({loading:true, message:"Login Progress....."});
    login({ Email, Password, AccountType }).then(data => {
      // console.log(data);
        if (data.error) {
          console.log(data.error);
            setError(data.error);
            // console.log(["test"]);
            // setError(["test"]);
            setUser({ ...user, error: data.error, loading: false });
            setLoading({loading:false, message:"Login Failed....."});
        } else {
          authenticate(data, () => {
            setProfile({
                Email: data.Email,
                FullName: data.FullName,
                ProfileImage: data.ProfileImage,
                AccountType: data.AccountType
            });
            setUser({
                ...user,
                redirectToReferrer: true
                , loading: false
            });
            setLoading({loading:false, message:"Login Success....."});
            // $(".btnRedirectHome").trigger("click");
            // redirectHome(true);
            history.push("/");
          });
        }
    });

  };


  const handleRegister = event => {
    console.log("Attempting Register...");

    event.preventDefault();
    const Email = document.getElementById("email").value;
    const Password = document.getElementById("password").value;
    const AccountType = "local";

    setUser({ ...user, error: "", loading: true, Email: Email, Password: "", AccountType: "local", FullName: "", ProfileImage: ""});
    setLoading({loading:true, message:"Register in Progress....."});
    register({ Email, Password, AccountType }).then(data => {
      // console.log(data);
        if (data.error) {
          console.log(data.error);
            setError(data.error);
            // console.log(["test"]);
            // setError(["test"]);
            setUser({ ...user, error: data.error, loading: false });
            setLoading({loading:false, message:"Registeration Failed....."});
        } else {
          authenticate(data, () => {
            setProfile({
                Email: data.Email,
                FullName: data.FullName,
                ProfileImage: data.ProfileImage,
                AccountType: data.AccountType
            });
            setUser({
                ...user,
                redirectToReferrer: true
                , loading: false
            });
            setLoading({loading:false, message:"Registeration Success....."});
            // $(".btnRedirectHome").trigger("click");
            // redirectHome(true);
            history.push("/");
          });
        }
    });

  };

  return (
      <Layout className='container no-bg'>
        <div className="form-modal">
          <div className="form-toggle">
            <button id="login-toggle" onClick={() => toggleSignin()}>
              <span className="icon">
                <SigninIcon />
              </span>
              &nbsp;Login
            </button>
            <button id="register-toggle" onClick={() => toggleRegister()}>
              <RegisterIcon />
              &nbsp;Register
            </button>
            {/* <button className='btnRedirectHome' style={{height:"0px"}} onClick={handleClick} type="button" /> */}
          </div>
          <div id="register-form">
            <form>
              <div style={{textAlign:"center"}}>
                  <button type="button" className="brd-curve" style={{width: "80%"}} onClick={googleLogin}>
                      <GoogleIcon/>
                      &nbsp;Signup with Google
                  </button>
                  
              </div>
              <p style={{textAlign:"center", padding:".5rem", marginBottom:"0"}}>Or</p>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Enter email"
              />
              <input
                id="mobile"
                name="mobile"
                type="numeric"
                placeholder="Enter mobile number"
              />
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Enter password"
              />
              <input
                id="cpassword"
                name="cpassword"
                type="password"
                placeholder="Confirm password"
              />
              {/* <select id="profileFor" name="profileFor" placeholder="Profile for">
                <option value="">Profile for...</option>
                <option>Self</option>
                <option>Friend</option>
                <option>Son</option>
                <option>Daughter</option>
                <option>Other</option>
              </select>
              <input id="name" name="name" type="text" placeholder="Enter Name" />
              <input
                id="dob"
                name="dob"
                type="text"
                placeholder="Date of Birth"
              />
              <select id="religion" name="religion" placeholder="Religion">
                <option value="">Religion...</option>
                <option>Hindu</option>
                <option>Christian</option>
                <option>Muslim</option>
              </select>
              <select
                id="mothertounge"
                name="mothertounge"
                placeholder="Mother Tounge"
                className="select__field"
              >
                <option value="">Mother Tounge...</option>
                <option>Tamil</option>
                <option>Malayalam</option>
                <option>Kannadam</option>
                <option>Telugu</option>
              </select> */}
              <p
                style={{
                  display: "table-cell",
                  marginBottom: "0",
                  verticalAlign: "middle",
                }}
              >
                <input
                  type="checkbox"
                  style={{ width: "20px", height: "15px" }}
                />
                I agree to the <a href="#">&nbsp;&nbsp;terms of services</a>{" "}
                &nbsp;&amp; &nbsp;<a href="#">Privacy policy</a>.
              </p>

              <button type="button" className="btn green" onClick={handleRegister}>
                Register
              </button>
            </form>
          </div>

          <div id="signin-form">
            <form>
              <div style={{textAlign:"center"}}>
                  <button type="button" className="brd-curve" style={{width: "80% !important"}} onClick={googleLogin}>
                      <GoogleIcon/>
                      &nbsp;Signin with Google
                  </button>
              </div>
              <p style={{textAlign:"center", padding:".5rem", marginBottom:"0"}}>Or</p>
              <input
                id="Email"
                name="Email"
                type="text"
                placeholder="Enter Email"
              />
              <input
                id="Password"
                name="Password"
                type="Password"
                placeholder="Enter Password"
              />
              {/* <button type="button" className="btn green">Login</button> */}
              <button type="button" className="btn green" onClick={handleLogin}>
                Login
              </button>
              <div>
                <p style={{ float: "left" }}>
                  <input
                    type="checkbox"
                    style={{ width: "20px", height: "15px" }}
                  />{" "}
                  Remember Me
                </p>
                <p>
                  <a style={{ float: "right" }} href="#">
                    Forgot Password?
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </Layout>
  );
}
export default Signin;
